import React from "react"

import "./styles.scss"

const Grid = ({ className = "", autoHeight = false, children, id="" }) => {
  return (
    <ul
      className={`grid ${className} ${autoHeight ? "grid--auto-height" : ""}`}
      id={`id`}
    >
      {children}
    </ul>
  )
}

export default Grid
