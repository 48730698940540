import React from 'react';
import Modal from 'react-modal';
import "./style.scss"

const buttonStyle = {
    background:'url(https://i.imgur.com/nTvElKZ.png) no-repeat',
    backgroundSize: 'contain',
    border: 'none',
    width: '20px',
    height: '20px',
    cursor: 'pointer',
}


interface Props {
    title: string;
    children: React.ReactNode;
    show: boolean;
    onClose: () => void;
}

export const GetModal = ({
    title,
    children,
    show,
    onClose,
}: Props) => {
    return (
        <Modal
            isOpen={show}
            onRequestClose={onClose}
            className="modal_window"  

        >
        
        <div 
        
            style={{
            display:'flex',
            flexDirection:'row', 
            margin:'4px 6px 4px 6px', 
            justifyContent:'space-between'}}
            > 
            
            <h2 style={{paddingBottom:'10px'}} >{title}</h2>
            <button 
                onClick={onClose} 
                style={buttonStyle} 
            />

        </div>
        
        
            {children}
           
        </Modal>  
    )
}