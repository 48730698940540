import React, { FunctionComponent } from "react"

import "./styles.scss"

interface FormSelectProps {
  label: string
  name: string
  options: string[]
  className?: string
  placeholder?: string
  value?: string
  required?: boolean
  id?: string
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void
}

const FormSelect: FunctionComponent<FormSelectProps> = ({
  label,
  name,
  options,
  className = "",
  placeholder = "Please select",
  value = "",
  required = false,
  children,
  id,
  onChange = () => {},
}) => {
  return (
    <div className={`form-select ${className}`}>
      <label className="form-select__label">{label}</label>
      <select
        id={id}
        name={name}
        className="form-select__field"
        defaultValue={value}
        required={required}
        onChange={onChange}
        value={value}
      >
        <option value="" disabled hidden>
          {placeholder}
        </option>
        {options?.map(oValue => (
          <option key={oValue} value={oValue} selected={value === oValue}>
            {oValue}
          </option>
        ))}
      </select>
      {children && <div className="form-select__appendix">{children}</div>}
    </div>
  )
}

export default FormSelect
