import React, { useContext, useEffect } from "react"
import Img from "gatsby-image"
import Hero from "../configurable/Hero"
import Heading from "../configurable/Heading"
import Block from "../configurable/Block"
import Image from "../configurable/Image"
import Icon from "../olc-framework/Icon"
import HighlightBlock from "../configurable/HighlightBlock"
import Grid from "../configurable/Grid"
import { filterProductsBy, filterProductsByAny, markdownNodesFilter } from "~/utils"
import Col9 from "../grid/Col9"
import Col4 from "../grid/Col4"
import Col8 from "../grid/Col8"
import Col11 from "../grid/Col11"
import Col6 from "../grid/Col6"
import Col7 from "../grid/Col7"
import Col5 from "../grid/Col5"
import TickList from "../configurable/TickList"
import BlockCTA from "../configurable/BlockCTA"
import "../configurable/ProductsAndWarrantiesBlock/styles.scss"
import "../configurable/BlockCTA/styles.scss"
import { CustomerTypeContext } from "~/providers/CustomerTypeProvider"
import Modal from 'react-modal';
import "./new-prods.scss"
import "./panels-info.scss"
import Col3 from "../grid/Col3"
import Section from "../configurable/Section"
import Col12 from "../grid/Col12"
import Col2 from "../grid/Col2"
import FormInput from "../olc-framework/FormInput"
import FormSelect from "../olc-framework/FormSelect"
import { GetModal } from "../configurable/Modal"
import Expandable from "../configurable/Expandable"

import * as InfogPanels from "~/vectors/infog_panels.inline.svg"

const content = {
  sunlight: {
    title: 'Sunlight',
    content: (
      <>
        <div className="modal_content_container" >
          <div className="modal_copy_inverter" >
            <p>The cells of a solar panel don’t need direct sunlight to work and can even work on cloudy days but will generate lower amounts of electrical energy.</p>
          </div>
        </div>
        <div className="modal_btn_container">
          {/* <button className="modal_btn">
          <a href='/commercial-products?filter=Panels'>View our panels</a>
        </button> */}
          <button className="modal_btn">
            <a href='/contact-us'>Ask a question</a>
          </button>
          <button className="modal_btn">
            <a href='/quote'>Get a quote</a>
          </button>
        </div>
      </>
    )
  },
  shading: {
    title: 'Shading',
    content: (
      <>
        <div className="modal_content_container" >
          <div className="modal_copy_inverter" >
            <p>Shading reduces the power output of a solar PV module and for standard string systems this will reduce the overall power output of all the modules connected to the string. In an Optimised system each modules operates independently and just the module that is shaded will see their power output drop</p>
          </div>
          <div className="modal_icon_container">
            <div>
              <Icon alias="sun" className="modal_icon" />
            </div>
            <div className="modal_icon_text" >
              <p>Shading one cell reduces the output of the whole string of cells or modules.</p>
            </div>
          </div>
          <div className="modal_icon_container">
            <div>
              <Icon alias="sun" className="modal_icon" />
            </div>
            <div className="modal_icon_text" >
              <p>Excess power from the unshaded cells is dissipated in the shaded cell.</p>
            </div>
          </div>
          <div className="modal_icon_container">
            <div>
              <Icon alias="sun" className="modal_icon" />
            </div>
            <div className="modal_icon_text" >
              <p>Bypass diodes isolate the shaded cell.</p>
            </div>
          </div>
        </div>
        <div className="modal_btn_container">
          {/* <button className="modal_btn">
          <a href='/commercial-products?filter=Other'>Lorem ipsum</a>
        </button> */}
          <button className="modal_btn">
            <a href='/contact-us'>Ask a question</a>
          </button>
          <button className="modal_btn">
            <a href='/quote'>Get a quote</a>
          </button>
        </div>
      </>
    )
  },
  string: {
    title: 'String',
    content: (
      <>
        <div className="modal_content_container" >
          <div className="modal_copy_inverter">
            <p>
              This is the most common set up for solar panels, whereby each solar module is daisy chained together, the first panel is connected into the second panel, and the third into the second and so on. Multiple strings or MPPT can be connected to one inverter for larger installations  </p>
          </div>
        </div>
        <div className="modal_btn_container" >
          <button className="modal_btn">
            <a href='/contact-us'>Ask a question</a>
          </button>
          <button className="modal_btn">
            <a href='/quote'>Get a quote</a>
          </button>
        </div>
      </>
    )
  },
  optimiser: {
    title: 'Optimiser or Micro-inverter ',
    content: (
      <>
        <div className="modal_content_container" >
          <div className="modal_copy_inverter" >
            <p>An optimiser is a DC-DC converter that is connected to a solar panel (or two panels for commercial installations), and it allows each panel to work individually with an inverter collecting the power output from each individual module. </p>
            <p>Like an optimized system, each panel is connected to their own individual micro-inverter, which allows for individual monitoring of each panel and is an ideal solution where there isn’t room to install a typical inverter</p>
          </div>
          {/* <div className="modal_icon_container">
          <div>
            <Icon alias="sun" className="modal_icon" />
          </div>
          <div className="modal_icon_text" >
            <p>Lorem ipsum dolor sit amet.</p>
          </div>
        </div> */}
        </div>
        <div className="modal_btn_container">
          {/* <button className="modal_btn">
          <a href='/commercial-products?filter=Other'>Lorem ipsum</a>
        </button> */}
          <button className="modal_btn">
            <a href='/contact-us'>Ask a question</a>
          </button>
          <button className="modal_btn">
            <a href='/quote'>Get a quote</a>
          </button>
        </div>
      </>
    )
  },
  inverter: {
    title: 'Inverter',
    content: (
      <>
        <div className="modal_content_container">
          <div className="modal_copy_inverter" >
            <p><strong>Location: </strong>inside or outside property</p>
            <p>Inverters are a crucial part of any solar energy sydghfstem. Their purpose is to convert the Direct Currect (DC) electricity that the solar panels produce into 240V Alternating Current (AC) electricity, which is what powers everything in your property. The inverter is a hardworking piece of equipment that works constantly throughout the lifetime of your system – so it tends to be the piece most likely to have faults. This means they usually only have a warranty of around 10 years. For this reason, we will always recommend that you choose a high quality inverter that is easily serviceable such as Sungrow or SolarEdge.</p>
          </div>
          <div className="modal_icon_container">
            <div>
              <Icon alias="sun" className="modal_icon" />
            </div>
            <div className="modal_icon_text" >
              <p>All our inverters come with 10+ years warranty </p>
            </div>
          </div>
        </div>
        <div className="modal_btn_container">
          {/* <button className="modal_btn">
          <a href='#productsGrid'>View our inverters</a>
        </button> */}
          <button className="modal_btn">
            <a href='/contact-us'>Ask a question</a>
          </button>
          <button className="modal_btn">
            <a href='/quote'>Get a quote</a>
          </button>
        </div>
      </>
    )
  },
  brand1: {
    title: (config) => config.title,
    content: (onViewAll, config) => (
      <>
        <div className="modal_content_container" >
          <div className="modal_copy_inverter" >
            <p><strong>Manufacturing Country: </strong>{config.manufaturer}</p>
            <p>{config.content}</p>
          </div>
          {/* <div className="modal_icon_container">
          <div>
            <Icon alias="sun" className="modal_icon" />
          </div>
          <div className="modal_icon_text" >
            <p>{config.fact}</p>
          </div>
        </div>  */}
        </div>
        <div className="modal_btn_container">
          <button className="modal_btn" onClick={() => onViewAll(config.brand)}>
            <a href='#productsGrid'>View all brand products</a>
          </button>
          <button className="modal_btn">
            <a href='/contact-us'>Ask a question</a>
          </button>
          <button className="modal_btn">
            <a href='/quote'>Get a quote</a>
          </button>
        </div>
      </>
    )
  },

}

const brandModalConfigs = {
  suntech: {
    manufaturer: 'China',
    content: 'Suntech, founded in 2001, as a famous photovoltaic manufacturer in the world, is devoted to the R & D and the production of crystalline silicon solar cells. ',
    fact: 'sofar is great',
    brand: 'Suntech',
    title: 'Suntech'
  },
  qcells: {
    manufaturer: 'Germany/Korea',
    content: "Hanwha Q Cells is a major manufacturer of photovoltaic solar cells. The company is headquartered in Seoul, South Korea, after being founded in 1999 in Thalheim, Germany.",
    fact: 'foxess is great',
    brand: 'Q Cells',
    title: 'Q Cells'
  },
  trinasolar: {
    manufaturer: 'China',
    content: "Trina Solar is a world leader in solar energy innovation and reliability. Power your energy future with industry-leading solar panels and solutions.",
    fact: 'huawei is great',
    brand: 'Trina Solar',
    title: 'Trina Solar'
  },
  phonosolar: {
    manufaturer: 'China',
    content: 'Phono Solar is a global leading manufacturer of high-performance photovoltaic products. Based on technological and product innovation, Phono Solar is committed to providing our customers with one-stop clean energy solutions, with wholesale energy supply, management, distribution and storage.',
    fact: 'solis is great',
    brand: 'Phono Solar',
    title: 'Phono Solar'
  },
  REC: {
    manufaturer: 'Norway',
    content: "Founded in 1996, REC Group is an international pioneering solar energy company dedicated to empowering consumers with clean, affordable solar power. REC is committed to high quality, innovation, and a low carbon footprint in the solar materials and solar panels it manufactures. Headquartered in Norway with operational headquarters in Singapore, REC also has regional hubs in North America, Europe, and Asia-Pacific.",
    fact: 'solaredge is great',
    brand: 'REC',
    title: 'REC'
  },
  jinko: {
    manufaturer: 'China ',
    content: 'JinkoSolar is one of the most innovative solar module manufacturers in the world, and have been vying for the position of the largest solar manufacturer.',
    brand: 'Jinko',
    title: 'Jinko'
  },

}


const panelsInfo = ({ markdownNodes }) => {
  const productsBlockRef = React.createRef() as React.RefObject<HTMLElement>

  const { customerType, setCustomerType } = useContext(CustomerTypeContext);

  const isDomestic = React.useMemo(() => customerType === "domestic", [customerType]);

  const [hasUsedFilters, setHasUsedFilters] = React.useState(true);

  //START FILTER LOGIC

  const productsWarranties = markdownNodesFilter(
    markdownNodes,
    "products_and_warranties"
  )

  const [brandFilter, setBrandFilter] = React.useState<string>('all');
  const [sizeFilter, setSizeFilter] = React.useState<string>('all');
  const [searchFilter, setSearchFilter] = React.useState<string>('');

  const panels = filterProductsBy(productsWarranties, 'category', 'panels');

  const [filteredProducts, setFilteredProducts] = React.useState<typeof panels>(panels);

  useEffect(() => {

    let filteredProducts = panels;

    if (brandFilter !== 'all') {
      filteredProducts = filterProductsBy(filteredProducts, "brand", brandFilter);
    }

    if (sizeFilter !== 'all') {
      filteredProducts = filterProductsBy(filteredProducts, "size", sizeFilter);
    }

    if (searchFilter !== '') {
      filteredProducts = filterProductsByAny(filteredProducts, ["brand", "size", "title", "description", "keywords"], searchFilter);
    }

    if (filteredProducts !== panels) {
      setHasUsedFilters(true);
    }

    setFilteredProducts(filteredProducts);

  }, [brandFilter, sizeFilter, searchFilter])

  const onFilterChange = (key: string, value: string) => {
    switch (key) {
      case "brand":
        setBrandFilter(value);
        break;
      case "size":
        setSizeFilter(value);
        break;
      case "search":
        setSearchFilter(value);
        break;
    }
  }

  //console.log("hasUsedFilters", hasUsedFilters)
  const showProducts = hasUsedFilters;

  //END FILTER LOGIC



  //START MODAL LOGIC

  let subtitle;

  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#f000';
  }

  const [modalContent, setModalContent] = React.useState(undefined);

  const [brandModalConfig, setBrandModalConfig] = React.useState(undefined);

  const closeModal = React.useCallback(() => {
    setModalContent(undefined);
  }, []);

  const onOpenModalFor = React.useCallback((name: string) => {
    setModalContent(content[name]);
  }, []);

  //END MODAL LOGIC

  const getModalContent = () => {
    if (modalContent?.content) {
      return typeof modalContent?.content === 'function' ? modalContent?.content((brand) => {
        setBrandFilter(brand);
        closeModal();
      }, brandModalConfig) : modalContent?.content
    }
    return modalContent?.content;
  }

  const getModalTitle = () => {
    if (modalContent?.title) {
      return typeof modalContent?.title === 'function' ? modalContent?.title(brandModalConfig) : modalContent?.title;
    }
    return '';
  }

  return (
    <div>
      <GetModal
        show={Boolean(modalContent)}
        onClose={closeModal}
        title={getModalTitle()}
      >
        {getModalContent()}
      </GetModal>

      <Hero imageUrl="/images/_breadcrumb17.png" compact>
        <Heading level={1} underlined>
          Our Products
        </Heading>
      </Hero>
      <Block>
        <Section className="introSec"  >
          <div className="container" >
            <Col12>
              <Heading level={1} >Solar PV Panels</Heading>
            </Col12>
            <div className="row">
              <Col12>
                <p className="bold-formatting">
                  A solar PV panel consists of many cells made from layers of semi-conducting material, most commonly silicon. When light shines on this material, a flow of <strong>DC electricity</strong> is generated. Most solar panels are made using either monocrystalline or polycrystalline silicon. From a practical perspective, there is very little difference between these two types. With that being said all panels supplied by Green Energy Together include the following protections:
                </p>
              </Col12>
            </div>
            <div className="row circlesArea">
              <Col2>
                <div className="circlesBox">
                  <div className="circle" >
                    <p style={{ fontSize: "2.5em" }}>25</p>
                  </div>
                </div>
                <p className="circleCaption" >
                  Linear<br />warranty
                </p>
              </Col2>
              <Col2>
                <div className="circlesBox">
                  <div className="circle" >
                    <p style={{ fontSize: "1.5em" }}>12-25</p>
                  </div>
                </div>
                <p className="circleCaption">
                  Product<br />warranty
                </p>
              </Col2>
              <Col2>
                <div className="circlesBox">
                  <div className="circle" >
                    <p style={{ fontSize: "2.5em" }}>2</p>
                  </div>
                </div>
                <p className="circleCaption">
                  Workmanship<br />warranty
                </p>
              </Col2>
            </div>
            <div className="row">
              <Col12>
                <p className="bold-formatting">
                  We'll look after you and your system in line with the data we receive from your <strong>remote monitoring solution</strong> included with your installation, while our Customer Care team will be available to answer your questions.
                </p>
              </Col12>
            </div>


          </div>
        </Section>

        <Section className="schematicSec"  >
          <div className="container" style={{ marginLeft: 0 }} >
            <Col12>
              <Heading level={2} underlined>Better your Solar Panels</Heading>
            </Col12>
            <div className="row">
              <Col12>
                <p >
                  The best way to ensure you get the most out of your Solar PV systems is with a battery. Why? Well, solar panels cannot store the electricity they produce unless you also have a battery fitted to your home. It’s not uncommon to just have solar panels, but in order to use the electricity produced for free, you must use it at the time it is generated- as it can’t be saved for later in the evening
                </p>
              </Col12>
            </div>
          </div>
          <div className="row" >
            <Col11>
              <div className="infocontainer hidden-xs" >
                <InfogPanels/>
                <button className="btn btn-panels-page sunlight" onClick={() => onOpenModalFor('sunlight')}>Sunlight</button>
                <button className="btn btn-panels-page shading" onClick={() => onOpenModalFor('shading')}>Shading</button>
                <button className="btn btn-panels-page string" onClick={() => onOpenModalFor('string')}>String</button>
                <button className="btn btn-panels-page optimiser" onClick={() => onOpenModalFor('optimiser')}>Optimiser or Micro-inverter </button>
                <button className="btn btn-panels-page inverter" onClick={() => onOpenModalFor('inverter')}>Inverter</button>
              </div>

              <div className="infocontainer visible-xs" >
                <img src={require('../../images/panels_infograph_cloud_sun.png')} />
                <button className="btn btn-panels-page sunlight" style={{ padding: '2px 5px' }} onClick={() => onOpenModalFor('sunlight')}>+</button>
                <button className="btn btn-panels-page shading" style={{ padding: '2px 5px' }} onClick={() => onOpenModalFor('shading')}>+</button>
                <button className="btn btn-panels-page string" style={{ padding: '2px 5px' }} onClick={() => onOpenModalFor('string')}>+</button>
                <button className="btn btn-panels-page optimiser" style={{ padding: '2px 5px' }} onClick={() => onOpenModalFor('optimiser')}>+</button>
                <button className="btn btn-panels-page inverter" style={{ padding: '2px 5px' }} onClick={() => onOpenModalFor('inverter')}>+</button>
              </div>

            </Col11>
          </div>
        </Section>

        <Section className="inverter-tech" >
          <div className="container" >
            <Col12>
              <Heading level={2} underlined>Benefits of a solar battery</Heading>
            </Col12>
            <div className="row bold-formatting">
              <Col12>
              <TickList nolines blueticks>
              <li>Renewable Energy: Solar gives you the opportunity to harness a renewable source of energy and reduces your carbon footprint
              </li>
              <li>Reduced energy costs: Why pay for something that is free? Solar allows you to use free energy and reduced your demand on the grid, which. in turn reduces you bill 
              </li>
              <li>Future Savings: Over the life time of your system the money you will save will pay back your investment 
              </li>
              <li>Optimisable: If you have a shaded roof, an optimised system would be best for you. It’s easy to add and will help you yield even more form your system. <a href="/accessories-info#optimiser">Want to know more?</a> 
              </li>
              <li>Battery Storage: Instead of exporting all the excess energy to the grid, battery storage is a feature you could add to your system.  This will allow you to store energy to use later! Keeping you off the grid for longer!  Check out our <a href="batteries-info/">battery solutions</a>.
              </li>
              <li>Export Tariff: Instead of letting excess energy go to waste, you can apply for the SEG tariff and get paid a small amount back for your excess energy. <a href="/seg">Click here</a> to read about SEG.
              </li>
              <li>Low Maintenance: Panels don’t require a ton of maintenance. They are really simple to look after and have a fantastic warranty.  
              </li>
                  </TickList>
              </Col12>
            </div>
          </div>
        </Section>



        <Section className="prodsSec" id="products" >
          <div className="container" >
            <Col12>
              <Heading level={2} underlined>Our Leading Manufacturers</Heading>
            </Col12>
            <div className="row">
              <Col12>
                <p >
                To help our customers find the ideal solar PV system, here is a collection of some of the solar panels we offer with technical specifications, performance details, technical characteristics and warranty. For further information, get in touch with one of our advisors.</p>
              </Col12>
            </div>
            <div className=" brands row" >
              <button>
                <img src={require('../../images/brands/phonosolar.png')} alt="longi" onClick={() => {
                  setBrandModalConfig(brandModalConfigs.phonosolar);
                  onOpenModalFor('brand1');
                }} />
              </button>
              <button>
                <img src={require('../../images/brands/rec.png')} alt="longi" onClick={() => {
                  setBrandModalConfig(brandModalConfigs.REC);
                  onOpenModalFor('brand1');
                }} />
              </button>
              <button>
                <img src={require('../../images/brands/suntech.png')} alt="suntech" onClick={() => {
                  setBrandModalConfig(brandModalConfigs.suntech);
                  onOpenModalFor('brand1');
                }} />
              </button>
            </div>
            <div className=" brands row" style={{marginTop:0}} >
              <button>
                <img src={require('../../images/brands/qcells.png')} alt="qcells" onClick={() => {
                  setBrandModalConfig(brandModalConfigs.qcells);
                  onOpenModalFor('brand1');
                }} />
              </button>
              <button>
                <img src={require('../../images/brands/trinasolar.png')} alt="trinasolar" onClick={() => {
                  setBrandModalConfig(brandModalConfigs.trinasolar);
                  onOpenModalFor('brand1');
                }} />
              </button>
              <button>
                <img src={require('../../images/brands/jinko.png')} alt="jinko" onClick={() => {
                  setBrandModalConfig(brandModalConfigs.jinko);
                  onOpenModalFor('brand1');
                }} />
              </button>



            </div>

          </div>
          <div className="row bold-formatting">
              <Col11 >
                <p>
                  <strong>Click on the filter</strong>  to select a category and display a selection of our products:
                </p>
              </Col11>
            </div>

          <div className="row fieldsArea">
            <Col4>
              <FormSelect
                name="brand"
                label="Filter by brand"
                options={["all", "Suntech", "Q Cells", "Longi", "Phono Solar", "Trina Solar", "REC", "Jinko"]}
                value={brandFilter}
                onChange={(evt) => { onFilterChange('brand', evt.target.value); }}
              />
            </Col4>
            <Col4>
              <FormSelect
                name="size"
                label="Filter by size"
                options={["all", "250W to 300W", "300W to 350W", "350W+"]}
                value={sizeFilter}
                onChange={(evt) => { onFilterChange('size', evt.target.value); }}
              />
            </Col4>
            <Col4>
              <FormInput
                name="keyword"
                label="Search for a product "
                placeholder="Start typing"
                value={searchFilter}
                onChange={(evt) => { onFilterChange('search', evt.currentTarget.value); }}
              />
            </Col4>
          </div>


          {showProducts && (
            <Grid id='productsGrid'>
              {filteredProducts.map(item => {
                const pwItem = item.frontmatter
                return (
                  <li key={item.fields.slug}>
                    {pwItem.image?.childImageSharp && (
                      <Img
                        fluid={pwItem.image.childImageSharp.fluid}
                        alt={pwItem.title}
                      />
                    )}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "top",
                        marginTop: 24,
                      }}
                    >
                      <p className="grid-item__title">{pwItem.title}</p>
                      {pwItem.pdf?.publicURL && (
                        <>
                          <a
                            href={pwItem.pdf.publicURL}
                            target="_blank"
                            style={{
                              display: "block",
                              marginTop: 0,
                              marginLeft: 24,
                            }}
                          >
                            <div
                              className="icon__circle-wrapper"
                              style={{ fontSize: 32, color: "#70b33b" }}
                            >
                              <Icon
                                alias="pdf"
                                className="grid__icon u-styling--box-shadow"
                              />
                            </div>
                          </a>
                        </>
                      )}
                    </div>
                  </li>
                )
              })}
            </Grid>
          )}
        </Section>


      </Block>
    </div>
  )
}

export default panelsInfo
